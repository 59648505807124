<script setup>
const ambiente_eni = process.env.VUE_APP_ENI_LDN
</script>

<template>
  <SisdaiNavegacionPrincipal>
    <template #complementario>
      <a
        class="nav-hipervinculo"
        :href="`${ambiente_eni}`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>IR A ENI</b>
      </a>
    </template>
    <ul class="nav-menu"></ul>
  </SisdaiNavegacionPrincipal>
</template>
