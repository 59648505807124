<script setup>
import { onBeforeUnmount, onMounted } from 'vue'

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const url_gema = process.env.VUE_APP_GEMA

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <div
        class="portada"
        style="background: #24455b"
      ></div>
      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">
              Seguridad Humana
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de Seguridad Humana
      </h2>

      <p class="texto-alto m-y-8-esc m-y-4-mov">
        El Ecosistema Nacional Informático de Seguridad Humana ofrece un espacio
        de acceso abierto para el análisis de datos y visualización de
        información generada en el marco del Programa Nacional Estratégico de
        Seguridad Humana.
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <div
        class="flex flex-contenido-separado flex-columnas-100-al-simplificar"
      >
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            href="https://seguridad.conahcyt.mx/mujeres/"
            target="_blank"
          >
            <picture class="fondo-imagen">
              <source
                :srcset="`${cdn_archivos_dominio}/tarjetas/genero-esc.png`"
                media="(min-width:768px)"
              />
              <img :src="`${cdn_archivos_dominio}/tarjetas/genero-mov.png`" />
            </picture>

            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Violencias y desigualdades de género
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="columna-8-esc m-t-3">
          <a
            class="tarjeta-hipervinculo-externo"
            :href="`${url_gema}/visualizador?seccion=seguridad-humana`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture class="fondo-imagen">
              <img
                class="fondo-imagen"
                :src="`${cdn_archivos_dominio}/tarjetas/eni-seguridad-humana-gema.png`"
                alt=""
              />
            </picture>
            <div class="degradado-negro-abajo">
              <div class="contenido-abajo">
                <p class="titulo-pagina texto-color-1">
                  Explora capas del tema seguridad humana
                </p>
                <hr />
                <p class="subtitulo-capitulo texto-color-1">
                  Gestor de mapas (Gema)
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
    }
  }
}
</style>
